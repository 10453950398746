import {
  Checkbox,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  InputLabel,
  Button,
  Breadcrumbs,
  Select,
  MenuItem,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../components/layout/Layout";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import LinkIcon from "@mui/icons-material/Link";
import AddIcon from "@mui/icons-material/Add";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import SearchIcon from "@mui/icons-material/Search";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import "./content.css";
import { useDispatch, useSelector } from "react-redux";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import {
  addContent,
  deleteContent,
  checkAllContent,
  unCheckAllContent,
  checkOneContent,
  unCheckOneContent,
  deleteAllContent,
  getContent,
  contentFlagOff,
  deleteContentSchedule,
  addContentSchedule,
  contentUpdate,
  getRouter,
  moveContentToFolder,
  _GetContentsByFolder,
} from "../../Redux/Actions/contentAction";
import Modal from "./FilterModel/ModelFilter";
import {
  AppsOutlined,
  LanguageOutlined,
  PanoramaHorizontalSelectOutlined,
  PhotoCameraBackOutlined,
} from "@mui/icons-material";
import { imgUrl } from "../../api/api";
import Image from "./component/image";
import Vedio from "./component/vedio";
import Url from "./component/url";
import arrowdown from "../../Assets/images/Solid.png";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import { Box } from "@mui/system";
import { ReactComponent as VideoIcon } from "../../Assets/images/videoIcon.svg";
import { ReactComponent as ImageIcon } from "../../Assets/images/imageIcon.svg";
import { ReactComponent as UrlIcon } from "../../Assets/images/urlIcon.svg";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import toast from "react-hot-toast";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getScreens } from "../../Redux/Actions/screenAction";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Pdf from "./component/Pdf";
import Loader from "../../components/loader/Loader";
import AppWidget from "./component/models/AppWidget";
import YoutubeWidget from "./widgets/youtube/YoutubeWidget";
import EmbedWidget from "./widgets/embed/EmbedWidget";
import AppWidgetForm from "./component/models/AppWidgetForm";
import WeatherWidget from "./widgets/weather/WeatherWidget";
import YoutubePlaylist from "./widgets/youtubeplaylist/YoutubePlaylist";
import Widget from "./component/Widget";
import FolderModel from "../../components/models/FolderModel";
import youtubelogo from "../../Assets/images/logos_youtube.svg";
import ytlistlogo from "../../Assets/images/Group 687.svg";
import vimo from "../../Assets/images/logos_vimeo-icon.svg";
import weather from "../../Assets/images/fluent_weather-haze-20-filled.svg";
import web from "../../Assets/images/fluent-mdl2_website.png";
import embd from "../../Assets/images/logos_embedly.svg";
import filterpng from "../../Assets/images/Frame 1261154641/Variant3.png";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FolderWrapper from "../../components/folder/FolderWrapper";
import FolderComponent from "../../components/folder/FolderComponent";
import { GetFolders } from "../../Redux/Actions/folderAction";
import useFilter from "../../hooks/useFilter";
import MiniLoader from "../../components/loader/MiniLoader";
import MoveFolder from "../../components/models/MoveFolder";
import VimeoWidget from "./widgets/vimeo/VimeoWidget";
import WebsiteWidget from "./widgets/website/WebsiteWidget";
import Clock from "./widgets/clock/clock";
import Chnnel from "./widgets/Channel/Channel";
const appCompnent = {
  default: "",
  youtube: YoutubeWidget,
  youtubeplaylist: YoutubePlaylist,
  embed: EmbedWidget,
  weather: WeatherWidget,
  vimeo: VimeoWidget,
  website: WebsiteWidget,
  Channel: Chnnel,
};
function Content({ socketRef, user }) {
  const navigate = useNavigate();
  // const [search] = useSearchParams();
  // const id = search.get("folder");
  const { _id } = useParams();
  const id = _id == null ? "Home" : _id;
  const dispatch = useDispatch();
  const {
    // content,
    contentByFolder,
    isContentAdding,
    isContentScheduled,
    isContentScheduledDeleted,
    isContentLoading,
    isContentAdded,
    isDeleting,
    isDeleted,
    isContentLoaded,
    isRouted,
    isContentMoving,
    isContentMoved,
  } = useSelector((state) => state.Content);
  const { folders, _folders, content } = useSelector((state) => ({
    folders: state.Folder.folders.filter(
      (f) => f.module === "content" && f.parent === id
    ),
    _folders: state.Folder.folders.filter((f) => f.module == "content"),
    // contentByFolder: state.Content.contentByFolder,
  }));
  const [path, setPath] = useState([
    { id: "home", name: "home", parent: null },
  ]);
  const [pdfForm, setPdfForm] = useState(false);
  const [openMediaPop, setOpenMediaPop] = useState(false);
  const [openEditUrlModel, setOpenEditUrlModel] = useState(false);
  const [ids, setIds] = useState("");
  const [urlContentForm, setUrlContentForm] = useState(false);
  const [urlName, setUrlName] = useState("");
  const [pdfInput, setPdfInput] = useState({
    thumbnail: "",
    thumbnailFile: "",
    pdfThumbnail: "",
    pdfFile: "",
  });
  const [createFolder, setCreateFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [urlContent, setUrlContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDeleteId, setContentDeleteId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [contentID, setContentID] = useState("");
  // const [filteredContent, setFilteredContent] = useState([]);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [day, setDay] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [openWidgets, setOpenWidgets] = useState(false);
  const [openWidgetForm, setOpenWidgetForm] = useState(false);
  const [widgetKey, setWidgetKey] = useState("default");
  const [isOpenFolderModel, setIsOpenFolderModel] = useState(false);
  const [_folderId, setFolderId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const AppComponent = appCompnent[widgetKey];
  const filteredContent = useFilter(searchText, contentByFolder);
  const [openDeleteFolder, setOpenDeleteFolder] = useState(false);
  const [countContent,setCountContent]=useState(0)
  const [selectedRow,setSelectedRow]=useState([]);
  const [isFolderOpen,setIsFolderOpen]=useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [_folderId, setFolderIds] = useState("");
  const [query, setQuery] = useState("");

  // const [folderPath,setFolderPath]=useState(false);
  const [days, setDays] = useState([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  const [listOfApps, setListOfApps] = useState([
    {
      name: "Vimeo",
      key: "vimeo",
      icon: vimo,
    },
    {
      name: "Youtube",
      key: "youtube",
      icon: youtubelogo,
    },
    {
      name: "Weather",
      key: "weather",
      icon: weather,
    },

    {
      name: "Embed",
      key: "embed",
      icon: embd,
    },
    {
      name: "YT/Playlist",
      key: "youtubeplaylist",
      icon: ytlistlogo,
    },

    {
      name: "Website",
      key: "website",
      icon: web,
    },
    {
      name: "Channel",
      key: "Channel",
      icon: web,
    },
  ]);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [filterOption,setFilterOption]=useState([]);
  const handleContextMenu = (event) => {
    // console.log(event);
    event.preventDefault();
    const rightDiv = document.querySelector(".right");
    const rightDivRect = rightDiv.getBoundingClientRect();
    setAnchorPosition({
      mouseX: rightDivRect.left + 10,
      mouseY: rightDivRect.top + 20,
    });
    setAnchorEl(rightDiv);
    setIsMenuVisible(true);
    // setAnchorPosition({
    //   mouseX: event.clientX,
    //   mouseY: event.clientY,
    // });
    // setAnchorPosition(
    //   anchorPosition === null
    //     ? { top: event.clientY, left: event.clientX }
    //     : null
    // );
  };

  const handleClose = () => {
    setAnchorPosition(null);
    setAnchorEl(null);
    setIsMenuVisible(false);
  };
  useEffect(() => {
    if (id !== "Home") {
      dispatch(_GetContentsByFolder(id));
    }
    // if (!id) return;
    if (id == "home" || id == "Home") return;
    const _folder = _folders?.find((f) => f._id == id);
    if (_folder != undefined || _folder != null || _folder != "") {
      // console.log("from useEffect->", _folder);
      setPath((prev) => [
        ...prev,
        { id: _folder?._id, name: _folder?.name, parent: _folder?.parent },
      ]);
    }
    // setFolderPath(true);
  }, [id]);

  useEffect(() => {
    // dispatch(getContent(user?._id))
    socketRef.current.emit("leaving--connection");
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    // dispatch(getContent(user?._id));
    dispatch(GetFolders(user?._id));
  }, []);
  let _content;
  let content_memo = (__content) => {
    let _c = [];
    return function (...cond) {
      // if (cond == null) {
      //   _c.push(...__content);
      //   return _c;
      // }
      if (cond) {
        _c.push(...__content);
        return _c;
      }
    };
  };
  _content = content_memo(filteredContent)(
    isContentLoaded,
    isContentAdded,
    isDeleted,
    isRouted,
    id
  );
  // useEffect(()=>{
  //   _content = content_memo(content)(
  //     isContentLoaded,
  //     isContentAdded,
  //     isDeleted,
  //     id
  //   );
  // },[id])
  // const _content = useMemo(() => {
  //   if (
  //     isContentLoaded ||
  //     isContentAdded ||
  //     isDeleted ||
  //     id == null ||
  //     id !== null
  //   ) {
  //     return [...content];
  //   }
  // }, [isContentLoaded, isContentAdded, isDeleted, id]);
  // console.log(_content);
  useEffect(() => {
    if (isContentAdded) {
      toast.success("Content added");
      // setFilteredContent([...content]);
      dispatch(contentFlagOff());
    }
    if (isDeleted) {
      toast.success("Content Deleted");
      // setFilteredContent([...content]);
      dispatch(contentFlagOff());
    }
    if (isContentScheduled) {
      toast.success("Playlist have scheduled");
      setIsScheduled(false);
      // const _checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (_checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(_checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
    if (isContentScheduledDeleted) {
      setOpenSchedule(false);
      toast.success("schedule deleted successfully");
      setIsScheduled(false);
      // const __checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (__checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(__checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
    if (isRouted) {
      dispatch(contentFlagOff());
    }
    if (isContentMoved) {
      toast.success("Content moved to folder.");
      setIsOpenFolderModel(false);
      dispatch(contentFlagOff());
    }
  }, [
    isContentAdded,
    isDeleted,
    isContentScheduled,
    isContentScheduledDeleted,
    isRouted,
    isContentMoved,
  ]);
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    // console.log(file)
    // console.log('from filehandler=>',bytesToSize(file.size), file);
    const form = new FormData();
    form.append("content", file);
    form.append("role", user?.role);
    form.append("userId", user?._id);
    form.append("folderId", id == null ? "Home" : id);
    // form.append("userId", user?.role === "admin" && user?._id);
    // form.append("subuser", user?.role === "user" && user?._id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const pdfContentSubmit = () => {
    const form = new FormData();
    form.append("pdf", pdfInput.pdfFile);
    form.append("thumbnail", pdfInput.thumbnailFile);
    // form.append("url", urlContent);
    form.append("role", user?.role);
    form.append("userId", user?._id);
    form.append("subuser", user?._id);
    form.append("folderId", id == null ? "Home" : id);
    // dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const urlContentSubmit = () => {
    const form = new FormData();
    form.append("name", urlName);
    form.append("contenttype", "url");
    form.append("url", urlContent);
    form.append("role", user?.role);
    form.append("userId", user?._id);
    form.append("subuser", user?._id);
    form.append("folderId", id == null ? "Home" : id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const handleDeleteContent = (id) => {
    // console.log(id)
    setOpenDialog(true);
    setContentDeleteId(id);
  };
  const confirmDeleteContent = () => {
    dispatch(deleteContent(contentDeleteId));
    setOpenDialog(false);
  };
  const searchHandler = (e) => {
    setSearchText(e);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const addSchedule = (contentid) => {
    const info = content.find((c) => c.id === contentid);
    // console.log('from addSchedule=>',info)
    const __startDate = new Date(info?.schedule?.startDate)
      .toLocaleDateString()
      .split("/");
    const __endDate = new Date(info?.schedule?.endDate)
      .toLocaleDateString()
      .split("/");
    const isStartDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __startDate[0]
    );
    const isEndDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __endDate[0]
    );
    const _startDate = `${__startDate[2]}-${
      isStartDateOneDigit !== undefined
        ? "0" + isStartDateOneDigit
        : __startDate[0]
    }-${__startDate[1]}`.toString();
    const _endDate = `${__endDate[2]}-${
      isEndDateOneDigit !== undefined ? "0" + isEndDateOneDigit : __endDate[0]
    }-${__endDate[1]}`.toString();
    if (info?.schedule?.scheduled) {
      setIsScheduled(true);
      // console.log("inside condition=>", info);
    }
    setContentID(contentid);
    setStartDate(_startDate);
    setEndDate(_endDate);
    setStartTime(info?.schedule?.startTime);
    setEndTime(info?.schedule?.endTime);
    setOpenSchedule(true);
    const dbDay = info.schedule.days;
    // console.log(dbDay)
    const sameArray = days.filter(
      (item) => !dbDay.find((d) => d.day === item.day)
    );
    // console.log([...sameArray,...dbDay]);
    setDays([...sameArray, ...dbDay]);
    setDay([...dbDay]);
  };

  const saveSchedule = () => {
    // console.log(day);
    dispatch(
      addContentSchedule(contentID, {
        startDate,
        endDate,
        startTime,
        endTime,
        days: day,
      })
    );
    setDay([]);
    setOpenSchedule(false);
  };
  const deleteScheduleFromContent = (id) => {
    dispatch(deleteContentSchedule(id));
  };
  const emitOnScheduleAdd = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("update_duration", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const cancelSchedule = () => {
    setOpenSchedule(false);
    // console.log([...days]);
    setDay([]);
    setDays([
      { _id: 1, day: "Mon", dayChecked: false },
      { _id: 2, day: "Tue", dayChecked: false },
      { _id: 3, day: "Wed", dayChecked: false },
      { _id: 4, day: "Thu", dayChecked: false },
      { _id: 5, day: "Fri", dayChecked: false },
      { _id: 6, day: "Sat", dayChecked: false },
      { _id: 7, day: "Sun", dayChecked: false },
    ]);
  };
  const handleDayCheckbox = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name,checked,value,days)
    if (checked) {
      setDay((prev) => [...prev, { day: value, dayChecked: true }]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: true };
      setDays([...days]);
      // console.log(selectedDayIndex)
    } else {
      setDay((prev) => [...day.filter((d) => d.day !== value)]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: false };
      // console.log(selectedDayIndex)
      setDays([...days]);
    }
    // console.log('days array=>',days)
  };

  const onEditContent = (_contentData) => {
    //  console.log(_contentData)
    setIds(_contentData.id);
    setUrlName(_contentData.name);
    setUrlContent(_contentData.url);
    setOpenEditUrlModel(true);
  };
  const urlContentUpdate = () => {
    dispatch(contentUpdate(ids, { url: urlContent, name: urlName }));
    setOpenEditUrlModel(false);
  };

  const onOpenWidgetForm = (_key, _index) => {
    setWidgetKey(_key);

    setOpenWidgetForm(true);

    // setOpenWidgets(false);
  };

  const callBack = (_index, _id) => {
    // alert(_id)
    if (id != null || id == null || _id == "Home") {
      dispatch(getRouter("Home"));
      // _content = content_memo(__content)(id);
    } else {
      dispatch(getRouter(_id));
    }
    setPath((prev) => prev.slice(0, _index + 1));
  };

  const onOpenFolderModel = (id) => {
    setIds(id);
    setIsOpenFolderModel(true);
  };
  const handleSearchChange = (event) => {
    setQuery(event.target.value);
    // You can add your search logic here
  };

  const __getFilterOption=useCallback((e)=>{
    const {name,checked}=e.target;
    if(checked){
      setFilterOption((prev)=>[...prev,name]);
    }else{
      setFilterOption(filterOption.filter(_f=>_f!=name));
    }
  },[filterOption])
const __filterApply=()=>{
  
}
const onEditFolder = (id) => {
  setFolderId(id);
  setIsEditMode(true);
  setCreateFolder(true);
};
const onDeleteFolder = (id) => {
  const _countContent = content.filter((s) => s?.folderId?._id == id);
  if (_countContent.length == 0) {
    setSelectedRow([]);
    setFolderId(id);
    setCountContent(0);
    setOpenDeleteFolder(true);
  } else {
    setSelectedRow(_countContent.map(c=>({_id:c.id,type:c.type,url:c.url})))
    setFolderId(id);
    setCountContent(_countContent.length);
    setOpenDeleteFolder(true);
  }
  // alert(`${_countScreen} screens in side your folder do you want to delete it? Screen also will remove.`)
};
  return (
    <Layout title="Content" user={user}>
      {/* <Clock/> */}
      {/* {console.log(_content)} */}
      {/* <iframe width="720" height="405" src="https://www.youtube.com/embed/?listType=playlist&list=PLxf3-FrL8GzTLaDiaApnrXeFVlUoUFjQz" frameborder="0" allowfullscreen></iframe> */}
      <div className="parent-container">
        <div className="child-container" style={{ height: "68px" }}>
          <Grid container>
            <div className={"control-area"}>
              {/* <Grid container> */}

              <div style={{ marginRight: "33vw" }}>
                <PrimarySearchBar value={searchText} onChange={searchHandler} />
              </div>
              <div lg={4} display={"flex"} justifyContent={"flex-end"}>
                <div className="user-add-btn" style={{ width: "243px" }}>
                  <button
                    style={{
                      height: "5.5vh",
                      width: "9vw",
                      marginTop: "2px",
                      marginLeft: "70px",
                    }}
                    disabled={
                      user.role == "user"
                        ? user?.previlliages?.folder?.canCreate
                          ? false
                          : true
                        : false
                    }
                    onClick={() => setCreateFolder(true)}
                  >
                    <AddIcon style={{ marginRight: "6px" }} /> Add Folder
                  </button>
                </div>
              </div>
              {/* </Grid> */}
            </div>
          </Grid>
          <div className="auser-add-btn">
            <button
              onClick={openModal}
              style={{
                height: "68px",
                width: "3.59vw",
                border: "none",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                background: "var(--primary-color)",
              }}
            >
              <img src={filterpng} alt="" />
            </button>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <div
                className="heading"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4>Filter page</h4>
                <span
                  onClick={closeModal}
                  style={{ marginTop: "18px", cursor: "pointer" }}
                >
                  <CloseIcon />
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // background: '#f0f0f0',
                  background: "white",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  height: "35px",
                  // width:'40vw',
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <SearchIcon style={{ color: "#888", marginRight: "8px" }} />
                <input
                  type="text"
                  value={query}
                  onChange={handleSearchChange}
                  placeholder="Search filter"
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    fontSize: "16px",
                    width: "100%",
                  }}
                />
              </div>
              <h6>Folders</h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // background: '#f0f0f0',
                  background: "white",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  height: "35px",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ color: " rgba(0, 0, 0, 0.6)" }}>Archive</span>
                <span>
                  {" "}
                  <img src={arrowdown} alt="" />
                </span>
              </div>
              <h5>Sources</h5>
              <div
                className="mainwrap"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div style={{ margin: "15px " }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'youtube'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      YouTube
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'video'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Video
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px", marginRight: "32px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'vimeo'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Vimeo
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'website'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Website
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px", marginRight: "35px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'news'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      News
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'image'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Image
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px", marginRight: "25px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'embed'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Embed
                    </div>
                  </label>
                </div>
                <div style={{ margin: "15px" }}>
                  <label>
                    <div className="wrapcheck" style={{ display: "flex" }}>
                      {" "}
                      <input
                        style={{
                          transform: "scale(1.5)", // Adjust the scale factor to increase/decrease size
                          width: "15px", // Optional: adjust width
                          height: "15px",
                          marginRight: "20px",
                        }}
                        name={'blogs'}
                        onChange={__getFilterOption}
                        type="checkbox"
                      />
                      Blogs
                    </div>
                  </label>
                </div>
              </div>
              <div
                className="btns"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  style={{
                    width: "120px",
                    borderRadius: "10px",
                    margin: "5px",
                    height: "45px",
                    gap: "5px",
                    background: "var(--primary-color)",
                    color: "white",
                    border: "none",
                  }}
                >
                  Apply Filter
                </button>
                <span
                  style={{
                    width: "120px",
                    borderRadius: "10px",
                    margin: "5px",
                    height: "45px",
                    gap: "5px",
                    color: "var(--primary-color)",
                    background: "white",
                    border: "1px solid var(--primary-color)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  Reset All
                </span>
              </div>
            </Modal>
          </div>
        </div>

        <div className="div" style={{ margin: "15px 0px" }}>
          {" "}
          <Breadcrumbs>
            {path?.map((_, index, _path) =>
              _path.length - 1 == index ? (
                <span
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  {_.name}
                </span>
              ) : (
                <Link
                  to={_.id == "home" ? "/content" : `/content/${_.id}`}
                  onClick={() => callBack(index, _.id)}
                  // onClick={() => setPath((prev) => prev.slice(0, index + 1))}
                  className="bread-crumbs-links"
                >
                  {_.name}
                </Link>
              )
            )}
          </Breadcrumbs>
        </div>
        <div style={{ display: "block", gap: "2px", margin: "0px 4px" }}>
          <div
            //  onContextMenu={handleContextMenu}
            // className={`${isMenuVisible ? 'left' : 'left'}`}
            style={{ width: "94%" }}
          >
            <div className="apps" style={{ display: "flex" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // border:'1px solid black',
                  padding: "2px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px 27px",
                    margin: "0 auto",
                    // border:'1px solid black'
                  }}
                >
                  {
                  id!=='Home' &&  
                  listOfApps.map((_, index, listOfApps) => (
                    <>
                      <Box
                        area-describedby={index}
                        onClick={() =>
                          id == "Home"
                            ? toast.success("Can not add outside folder.")
                            : onOpenWidgetForm(_?.key, index)
                        }
                        key={index}
                        style={{
                          display: `${
                            user.role == "user"
                              ? user.previlliages.content.canCreate
                                ? "flex"
                                : "none"
                              : "flex"
                          }`,
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                          width: "7vw",
                          // marginRight: "12px",
                          // marginLeft: "8px",
                          borderRadius: 5,
                          cursor: "pointer",
                          background: "white",
                          alignSelf: `${
                            index === listOfApps.length - 1 && "flex-end"
                          }`,
                        }}
                      >
                        <img
                          src={_.icon}
                          style={{
                            height: "55%",
                            width: "50%",

                            objectFit: "contain",
                          }}
                        />
                        <span style={{ fontSize: "small", marginTop: "5px" }}>
                          {_.name}
                        </span>
                      </Box>
                    </>
                  ))}
                  {
                    id!=='Home' &&
                  <div
                    style={{
                      width: "100%",
                      // border: "1px solid black",
                      borderRadius: 5,
                      height: "10vh",
                      width: "7vw",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "1px",
                      color: "black",

                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "2vw",
                          height: "3vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="file"
                          hidden
                          id="photo"
                          onChange={(e)=>{
                            id == "Home"
                              ? toast.success("Can not add outside folder.")
                              : fileHandler(e)
                          }
                            
                          }
                          disabled={
                            user.role == "user"
                              ? user?.previlliages?.content?.canCreate
                                ? false
                                : true
                              : false
                          }
                        />
                        
                        {
                        id!="Home" ?
                        user.role != "admin" ? (
                          <label
                            style={{
                              color: `${
                                user.previlliages.content.canCreate
                                  ? "black"
                                  : "black"
                              }`,
                              cursor: "pointer",
                            }}
                            htmlFor={ "photo"}
                          >
                            <div className="icon" style={{ color: "black" }}>
                              {" "}
                              <AddIcon fontSize="large" />
                            </div>
                          </label>
                        ) : (
                          <label
                            style={{ color: "white", cursor: "pointer" }}
                            htmlFor={ "photo"}
                            // onClick={() => setOpenMediaPop(true)}
                          >
                            <div className="icon" style={{ color: "black" }}>
                              {" "}
                              <AddIcon fontSize="large" />
                            </div>
                          </label>
                        ) : <></>}
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

            {isContentAdding && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    height: "100vh",
                    width: "100vw",
                    background: "rgba(0,0,0,0.5)",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: "1000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </Box>
              </>
            )}

            <FolderWrapper>
              {folders?.map((_folder, index) => (
                <FolderComponent
                  key={index}
                  index={index}
                  _folder={_folder}
                  _module={"content"}
                  onEdit={onEditFolder}
                  onDeleteFolder={onDeleteFolder}
                />
              ))}
            </FolderWrapper>
            {id !== "Home" && (
              <>
                {(isContentLoading && _content?.length > 0) ||
                  (isDeleting && <Loader />)}
                <div
                  className="flex flex-wrap justifyContentStart alignItemCenter margin"
                  style={{ width: "75vw", gap: "15px" }}
                >
                  {isContentLoading && _content?.length == 0 ? (
                    <Loader />
                  ) : (
                    filteredContent.length > 0 &&
                    filteredContent.map((item, index) => (
                      <>
                        {item.type === "image" && (
                          <Image
                            key={index}
                            data={item}
                            addSchedule={addSchedule}
                            deleteSingleContent={handleDeleteContent}
                            onOpenFolderModel={onOpenFolderModel}
                          />
                        )}
                        {item.type === "video" && (
                          <Vedio
                            key={index}
                            data={item}
                            addSchedule={addSchedule}
                            deleteSingleContent={handleDeleteContent}
                            onOpenFolderModel={onOpenFolderModel}
                          />
                        )}
                        {item.type === "url" && (
                          <Url
                            key={index}
                            data={item}
                            addSchedule={addSchedule}
                            deleteSingleContent={handleDeleteContent}
                            editContent={onEditContent}
                            onOpenFolderModel={onOpenFolderModel}
                          />
                        )}
                        {item.type === "application" && (
                          <Pdf
                            key={index}
                            data={item}
                            addSchedule={addSchedule}
                            deleteSingleContent={handleDeleteContent}
                            onOpenFolderModel={onOpenFolderModel}
                          />
                        )}
                        {item.type == "widget" && (
                          <Widget
                            key={index}
                            data={item}
                            addSchedule={addSchedule}
                            deleteSingleContent={handleDeleteContent}
                            onOpenFolderModel={onOpenFolderModel}
                          />
                        )}
                      </>
                    ))
                  )}
                </div>
              </>
            )}
          </div>

          <div
            className="right"
            // className={`${isMenuVisible ? 'right' : 'right'}`}
            style={{
              width: "23%",
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></div>
        </div>
      </div>

      <FolderModel
        open={createFolder}
        // onClose={() => setCreateFolder(false)}
        // module={"content"}
        // parent={id == "" ? "home" : id}
        // userId={user._id}
        module={"content"}
        onClose={() => {
          setCreateFolder(false);
          setIsEditMode(false);
        }}
        parent={id == "" ? "home" : id}
        userId={user?._id}
        isEditMode={isEditMode}
        folderId={_folderId}
      />

      <MoveFolder
        isOpen={isOpenFolderModel}
        onClose={() => setIsOpenFolderModel(false)}
        onGetFolderId={(e) => setFolderId(e)}
        _folders={_folders}
        isMoving={isContentMoving}
        moveMethod={moveContentToFolder(ids, _folderId)}
      />

      <Dialog
        sx={{ zIndex: "10000000000000" }}
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid var(--primary-color)",
          }}
        >
          <ScheduleSendIcon sx={{ color: "var(--primary-color)" }} /> Schedule
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "50vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "97%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-date">Start Date</InputLabel>
                  <TextField
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id={"start-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-date">End Date</InputLabel>
                  <TextField
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id={"end-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-time">Start Time</InputLabel>
                  <TextField
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    id={"start-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-time">End Time</InputLabel>
                  <TextField
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    id={"end-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                id={"days-id"}
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {days.map((d, index) => {
                  return (
                    <>
                      <input
                        onChange={handleDayCheckbox}
                        name={d._id}
                        value={d.day}
                        checked={d.dayChecked ? true : false}
                        type={"checkbox"}
                        id={`day-${index}`}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`day-${index}`}
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          height: "50px",
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                      >
                        {d.day}
                      </label>
                    </>
                  );
                })}
              </Box>

              <Box
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Button
                  variant={"outlined"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={cancelSchedule}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={saveSchedule}
                >
                  Save
                </Button>
                {isScheduled ? (
                  <>
                    <Button
                      variant="contained"
                      color={"error"}
                      onClick={() => deleteScheduleFromContent(contentID)}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <DeleteModel
        open={openDialog}
        onClose={(e) => setOpenDialog(e)}
        onConfirm={confirmDeleteContent}
      />

      {/* <AppWidget
        maxWidth={"md"}
        isFullWidth={true}
        isOpen={openWidgets}
        onClose={() => setOpenWidgets(false)}
        onOpenWidgetForm={onOpenWidgetForm}
      /> */}

      <AppWidgetForm
        maxWidth={"lg"}
        isOpen={openWidgetForm}
        onClose={() => {
          setOpenWidgetForm(false);
          // setOpenWidgets(true);
        }}
      >
        <AppComponent
          folderId={id}
          onClose={() => {
            setOpenWidgetForm(false);
            setOpenWidgets(true);
          }}
        />
      </AppWidgetForm>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openMediaPop}
        onClose={() => setOpenMediaPop(false)}
        sx={{
          zIndex: "100000000",
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "3px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Add Content
        </DialogTitle>
        <DialogContent>
          <div className="content-add-media-box">
            <input type="file" hidden id="photo" onChange={fileHandler} />
            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <PhotoCameraBackOutlined className="add-media-icon" />
                <p className="media-text">Image</p>
              </label>
            </div>
            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <SlowMotionVideoOutlinedIcon className="add-media-icon" />

                <p className="media-text">Video</p>
              </label>
            </div>

            <div
              className="add-media-tab add-media-tab-flex"
              onClick={() => setUrlContentForm(!urlContentForm)}
            >
              <LanguageOutlined
                className="add-media-icon"

                // style={{
                //   background: isActive ? 'white' : 'white',
                // }}
              />
              <p
                className="media-text"
                //  style={{
                //   color: isActive ? 'white' : '',
                // }}
                // onClick={handleClick}
              >
                Url
              </p>
            </div>

            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <PictureAsPdfOutlinedIcon className="add-media-icon" />
                <p className="media-text">Pdf</p>
              </label>
            </div>
            <div
              className="add-media-tab"
              onClick={() => {
                setOpenMediaPop(false);
                setOpenWidgets(true);
              }}
            >
              <label htmlFor="" className="add-media-label">
                <AppsOutlined className="add-media-icon" />
                <p className="media-text">Apps</p>
              </label>
            </div>
          </div>

          {pdfForm && (
            <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type={"file"}
                  id={"thumbnail"}
                  style={{ border: "none" }}
                  onChange={(e) =>
                    setPdfInput((prev) => ({
                      ...prev,
                      thumbnail: e.target.files[0].name,
                      thumbnailFile: e.target.files[0],
                    }))
                  }
                  hidden
                />
                <input
                  type={"file"}
                  id={"pdffile"}
                  style={{ border: "none" }}
                  onChange={(e) =>
                    setPdfInput((prev) => ({
                      ...prev,
                      pdfThumbnail: e.target.files[0].name,
                      pdfFile: e.target.files[0],
                    }))
                  }
                  hidden
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label
                    htmlFor="thumbnail"
                    style={{
                      height: "30px",
                      width: "150px",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      color: "black",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {pdfInput.thumbnail == "" ? (
                      <>Select Thumbnail</>
                    ) : (
                      <>{pdfInput.thumbnail?.substring(0, 10)}</>
                    )}
                  </label>
                  <label
                    htmlFor="pdffile"
                    style={{
                      height: "30px",
                      width: "150px",
                      maxWidth: "300px",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      color: "black",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {pdfInput.pdfThumbnail == "" ? (
                      <>Select Pdf</>
                    ) : (
                      <>{pdfInput.pdfThumbnail?.substring(0, 10)}</>
                    )}
                  </label>
                </div>

                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={pdfContentSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {urlContentForm && (
            <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  value={urlName}
                  onChange={(e) => setUrlName(e.target.value)}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  variant="standard"
                  value={urlContent}
                  onChange={(e) => setUrlContent(e.target.value)}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={urlContentSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openEditUrlModel}
        onClose={() => setOpenEditUrlModel(false)}
      >
        <DialogTitle>Url Edit</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
                label="Title"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
              <TextField
                variant="standard"
                value={urlContent}
                onChange={(e) => setUrlContent(e.target.value)}
                label="Url Link"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={urlContentUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default Content;
