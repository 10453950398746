import React from 'react'
import "./Cards.css"
function MiniCard({icon,data}) {
  return (
   <>
     <div
              className="mini-card"
              >
                <div className='minictext' style={{width:'90%',height:'20%',fontSize:'15px',color:'white',fontWeight:'600',display:'flex',justifyContent:'center',marginTop:'15px'}}>
                  {data?.day}
                </div>
                <img style={{width:"78px",height:"78px",marginTop:"20px"}}
                                  src={`https://openweathermap.org/img/wn/${data.weather[0].icon}.png`}
                                  alt="icon"
                                />
                {/* <img src={icon} style={{height:'50%',width:'50%'}}/> */}
                <span style={{color:'white',fontWeight:'500',marginTop:"15px",fontSize:"18px"}}>{data.main.temp}°C</span>
                {/* <h5 style={{color:'white',fontWeight:'bold'}}>{data.weather[0].description}</h5> */}
              </div>
   </>
  )
}

export default MiniCard
