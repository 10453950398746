import React from 'react'
import AppWidgetForm from "../../component/models/AppWidgetForm"; // Import modal component
import success from "../../../../Assets/images/Group 1000005274.svg"
import "./Channel.css";
const SuccessDone = ({isOpen,onClose}) => {
  return (
   <>
    <AppWidgetForm
        isOpen={isOpen}
        onClose={onClose}
        style={{ height: "60v", width: "40vw" }}
      >
        <div className="contetsuccess">
            <img src={success} alt="" />
        </div>
        <div className="successtext">
            <span className='span1'>Create Channel Successful!</span>
            <span className='span2'>Your Created Channel Is Successfully Done</span>
            <span className='span3'>Back To Home</span>
        </div>
        </AppWidgetForm></>
  )
}

export default SuccessDone
