import React, { useEffect } from "react";
import Clear from "../../../../../Assets/icons/sun.png";
import Rainy from "../../../../../Assets/icons/rain.png";
import Cloudy from "../../../../../Assets/icons/cloud.png";
import Haze from "../../../../../Assets/icons/fog.png";
import locationiscon from "../../../../../Assets/icons/location-01-stroke-standard (1) 1.png";
import humidity from "../../../../../Assets/icons/slow-winds-solid-standard 1.png"
import "./Cards.css";
function MainCard({ icon, weather }) {
  const imgDictionary = {
    Clear: Clear,
    Rainy: Rainy,
    Clouds: Cloudy,
    Haze: Haze,
  };
  return (
    <>
      <div className="main">
        <div className="heading">
          <h3>Preview*</h3>
        </div>
        <div className="weathercard">
          <div className="column1">
            <div className="location-name">
              <img src={locationiscon} alt="" />
              {weather?.name}
            </div>
<div className="weathertype" >
{weather?.weather}
{/* {weather?.list.feels_like} */}
{/* {weather?.main.feels_like} */}
</div>
          </div>
          <div className="column2">
            <div className="weatherimage">
            <img
              src={imgDictionary[weather.weather]}
            />
            <div className="temprature">
            {weather?.temp}°
            <div className="cel">
              c
            </div>
            </div>
            </div>

          </div>
          <div className="column3">
            <div className="date">
              29/sep/24
            </div>
        
        <div className="humidity">
          
          <div className="icon"> <img src={humidity} alt="" /></div>
         
       <div className="valhumi"> {weather?.humidity}% </div>
          <div className="texthumi">Humidity</div>
        </div>
        <div className="humidity">
          
          <div className="icon"> <img src={humidity} alt="" /></div>
         
       <div className="valhumi">  {weather?.wind}K/H</div>
          <div className="texthumi">Wind Speed</div>
        </div>
         
          </div>

         

        

         
        

          <line />
        </div>
      </div>
    </>
  );
}

export default MainCard;
