// src/Modal.js

import React from 'react';
import './Modals.css'; // Import custom styles

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" style={{background:"rgba(0,0,0,0.01"}}  >
    
      <div className="modal-content" >
        {children}
      </div>
    </div>
  );
};

export default Modal;
