import React,{useState} from 'react'
import CloseIcon from "@mui/icons-material/Close";
import AppWidgetForm from "../../component/models/AppWidgetForm";

import { DialogActions, Button } from "@mui/material";
import frame1 from "../../../../Assets/images/Frame1.png";
import frame2 from "../../../../Assets/images/Frame 2.png";
import frame3 from "../../../../Assets/images/Frame 3.png";
import frame4 from "../../../../Assets/images/Frame 4.png";
import frame5 from "../../../../Assets/images/Frame5.png";
import frame6 from "../../../../Assets/images/Frame6.png";

const LayoutChoose = (
    {isOpen, onClose}
) => {
    const [listOfCards, setListOfCards] = useState([
        {
          name: "Main",
          img: frame1,
        },
        {
          name: "Main +  Footer",
          img: frame2,
        },
        {
          name: "Main + Laft  Bar",
          img: frame3,
        },
        {
          name: "Main + Right Bar",
          img: frame4,
        },
        {
          name: "Split Vertically",
          img: frame5,
        },
        {
          name: "Split Horizantally",
          img: frame6,
        },
      ]);
  return (
   <>
    <AppWidgetForm
     isOpen={isOpen} onClose={onClose} 
        style={{ height: "60vh", width: "40vw" }}
       
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding: "15px",
            marginTop: "8px",
            width: "32vw",
          }}
        >
          <span>Choose Layout</span>

          <span
            style={{
              border: "none",
              background: "transparent",
              color: "black",
              cursor: "pointer",
            }}
            // onClick={handleCloseModal}
          >
            {" "}
            <CloseIcon />
          </span>
        </div>
        <div className="content" style={{width:"35vw",height:"42vh", display:"flex",justifyContent:"center"}}>
        <div className="cards"  style={{height:"113px",display:"flex",flexWrap:"wrap", margin:"0px", gap:"20px",cursor:"pointer"}}>
        
          {listOfCards.map((key,index) => (
            <>
           
                <div className="maincard"style={{width:"146px",height:"113px", margin:"16px"}}>
                <div className="img" style={{width:"146px",height:"91px",border:"0.5px solid #EEEDED",borderRadius:"8px",display:"flex",alignItems:"center",justifyContent:"center"}}> <img src={key.img} alt="" /></div>
                <div className="name" style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"12px"}}>{key.name}</div>
                </div>
            </>
          ))}
        </div>
        </div>
        <div style={{ display: "flex" }}>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                  }}
                 
                >
                  Select
                </Button>
              </div>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "white",
                    color:"var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                    border:"1px solid var(--primary-color)"
                  }}
                //   onClick={handleCloseModal}
                >
              Close
                </Button>
              </div>
            </div>
           
      </AppWidgetForm></>
  )
}

export default LayoutChoose