import React from 'react'
import "./dashboard.css";
import { Grid } from "@mui/material";
import screen from "../../Assets/icons/Vector.svg";
import screen1 from "../../Assets/icons/iconoir_page.svg";
import screen2 from "../../Assets/icons/Vector a.svg";
import User from "../../Assets/icons/user.svg";

const DashboardTopBoxes = () => {
  return (
   <>
     <Grid>
              <div className="mainbox">
                <div className="box1">
                  <div
                    className="circle"
                    style={{ background: "hsla(337, 79%, 61%, 1)" }}
                  >
                    <img src={User} alt="" />
                  </div>
                  <span>abc@gmail.com</span>
                </div>
                <div className="boxe2">
                  <div
                    className="circle"
                    style={{ background: "hsla(143, 53%, 50%, 1)" }}
                  >
                    <img src={screen} alt="" />
                  </div>
                  <span>Screen</span>
                  <span>3</span>
                </div>
                <div className="boxe2">
                  <div
                    className="circle"
                    style={{ background: " hsla(209, 99%, 65%, 1)" }}
                  >
                    <img src={screen1} alt="" />
                  </div>
                  <span>Content</span>
                  <span>11</span>
                </div>
                <div className="boxe2">
                  <div
                    className="circle"
                    style={{ background: " hsla(14, 81%, 46%, 1)" }}
                  >
                    <img src={screen2} alt="" />
                  </div>
                  <span>Playlist</span>
                  <span>6</span>
                </div>
              </div>
            </Grid>
   </>
  )
}

export default DashboardTopBoxes
