import React,{useState} from "react";
import { Button } from "@mui/material";
import AppWidgetForm from "../../component/models/AppWidgetForm"; // Import modal component
import CloseIcon from "@mui/icons-material/Close";
import Layout from "./Layout";

const  PlaylistSelectmodel = ({ isOpen, onClose }) => {
    const [LayoutModel, setLayoutModel] = useState(false);
    const handleOpenSecondModal = () => {
        setLayoutModel(true);
       
       
      };
    
      const handleCloseSecondModal = () => {
        setLayoutModel(false);
      };

  return (
    <>
    <AppWidgetForm
    isOpen={isOpen} onClose={onClose}
    style={{ height: "60vh", width: "40vw" }}
  >
    <div className="channelmainnamea">
      <span>Add Playlist to Channel Section</span>

      <span
        style={{
          border: "none",
          background: "transparent",
          color: "black",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        {" "}
        <CloseIcon />
      </span>
    </div>
    <div className="mainwrap">
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px", marginRight: "13px" }}>
              Oppo Webview
            </span>
          </div>
        </label>
      </div>

      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px" }}>Final Video</span>
          </div>
        </label>
      </div>
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px", marginRight: "47px" }}>
              Web Page
            </span>
          </div>
        </label>
      </div>
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px" }}>Khttk</span>
          </div>
        </label>
      </div>
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px" }}>Right Bar Playlist</span>
          </div>
        </label>
      </div>
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px" }}>Main Playlist</span>
          </div>
        </label>
      </div>
      <div style={{ margin: "20px " }}>
        <label>
          <div
            className="wrapcheck"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="blogs-checkbox"
              />
              <span className="checkmark"></span> {/* Custom checkmark */}
            </div>
            <span style={{ marginLeft: "20px" }}>Kotlin Testing</span>
          </div>
        </label>
      </div>
    </div>
    <div style={{ display: "flex" }}>
      <div
        className="btnn"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "16px",
        }}
      >
        <Button
          variant="contained"
          style={{
            background: "var(--primary-color)",
            width: "151px",
            height: "40px",
            padding: 20,
            borderRadius: "8px",
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
      <div
        className="btnn"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "16px",
        }}
      >
        <Button
          variant="contained"
          style={{
            background: "white",
            color: "var(--primary-color)",
            width: "151px",
            height: "40px",
            padding: 20,
            borderRadius: "8px",
            border: "1px solid var(--primary-color)",
          }}
          onClick={handleOpenSecondModal}
        >
          Save
        </Button>
      </div>
    </div>
  </AppWidgetForm>
    <Layout isOpen={LayoutModel} onClose={handleCloseSecondModal} />
    </>
  );
};

export default  PlaylistSelectmodel;

