import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from "../../Assets/icons/toggle-right.svg";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import ListIcon from "@mui/icons-material/List";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import AreaChart from "../../components/charts/AreaChart";
import DonutChart from "../../components/charts/DonutChart";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { Label } from "@mui/icons-material";
import { Grid,  ListItem,  } from "@mui/material";
import { Box } from "@mui/system";
import { styled, alpha } from "@mui/material/styles";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getScreens } from "../../Redux/Actions/screenAction";
import { getContent } from "../../Redux/Actions/contentAction";
import { getUsers, login } from "../../Redux/Actions/userAction";
import { GetPrevilliages } from "../../Redux/Actions/PrevilliageAction";
import PrimarySearchBar from "../../components/searchBar/searchbar";


import DashboardTopBoxes from "./DashboardTopBoxes";
import DashboardScreenCount from "./DashboardScreenCount";
import DashboardBottomGraphs from "./DashboardBottomGraphs";
const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
}));

function Dashboard({ user, socketRef }) {
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
      url: {
        main: "#FD2254",
      },
      image: {
        main: "#FF6A00",
      },
      video: {
        main: "#551fff",
      },
    },
  });
  const CustomLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
    height: 10,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color,
    },
  }));
 
  const contentData = [
    { label: 'URL', value: 14, color: ' hsla(209, 99%, 65%, 1)' },
    { label: 'Image', value: 32, color: ' hsla(143, 53%, 50%, 1)' },
    { label: 'Video', value: 4, color: 'hsla(14, 81%, 46%, 1)' },
    { label: 'Widgets', value: 6, color: 'hsla(337, 79%, 61%, 1)' },
  ];
  const dispatch = useDispatch();
  const { content, isContentLoading, contents } = useSelector(
    (state) => state.Content
  );
  const { playlist, isPlaylistLoading } = useSelector(
    (state) => state.Playlist
  );
  const { screens, screenLoading } = useSelector((state) => state.Screen);
  const { users } = useSelector((state) => state.User);
  useEffect(() => {
    socketRef.current.emit("leaving--connection");
    dispatch(getContent(user?._id));
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getUsers(user?._id));
    dispatch(GetPrevilliages(user._id));
  }, []);

  return (
    <>
      <Layout title={"Dashboard"} user={user}>
        <ThemeProvider theme={theme}>
          <Grid container px={2}>
            <Grid container>
              <div className="acontrol-area">
                <div>
                  <PrimarySearchBar
                  // value={searchText}
                  // onChange={searchHandler}
                  />
                </div>
                <div lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn" style={{ width: "243px" }}>
                    <button
                      style={{
                        height: "35px",
                        width: "151px",
                        marginTop: "2px",
                        marginLeft: "70px",
                        borderRadius: "8px",
                      }}
                      disabled={
                        user.role == "user"
                          ? user?.previlliages?.folder?.canCreate
                            ? false
                            : true
                          : false
                      }
                      // onClick={() => setCreateFolder(true)}
                    >
                      <img
                        src={AddIcon}
                        alt=""
                        style={{ marginRight: "16px" }}
                      />
                      De _Active
                    </button>
                  </div>
                </div>
                {/* </Grid> */}
              </div>
            </Grid>
          <DashboardTopBoxes/>
          <DashboardScreenCount/>
          <DashboardBottomGraphs/>
            {/* <Grid lg={8.5} md={8.5} pr={2}>
              <Grid
                container
                sx={{
                  padding: "30px 13px 0 15px",
                  background: "#FFFFFF",
                  boxShadow: "0px 18px 32px rgba(208, 210, 218, 0.15)",
                  borderRadius: "20px",
                }}
              >
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <TextSnippetIcon
                      sx={{
                        background: "#FFF2E9",
                        padding: "15px",
                        color: "#FF6A00",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Content
                      </Typography>
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {contents.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#FFF2E9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#FF6A00", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <DesktopMacIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#551fff",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Add Screens
                      </Typography>
                      {screenLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {screens.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#EDE8FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#551fff", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ListIcon
                      sx={{
                        background: "#EAF9FF",
                        padding: "15px",
                        color: "#00B7FE",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Playlist
                      </Typography>
                      {isPlaylistLoading ? (
                        <Box className={"minLoader"}></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {playlist.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#EAF9FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#00B7FE", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={1}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <PersonAddIcon
                      sx={{
                        background: "#FFEBEF",
                        padding: "15px",
                        color: "#FD2254",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Followers
                      </Typography>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "14px",
                        }}
                      >
                        {users.length}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#FFEBEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#FD2254", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  marginTop: "20px",
                  marginBottom:"20px",
                  background: "#FFFFFF",
                  // boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                  borderRadius: "20px",
                  padding: "20px",
                  //height:'100%',
                  width:{xl:'1100',lg:'1100',md:'1100',sm:'95%',xs:'95%'}
                }}
              >
                <AreaChart />
              </Box>
            </Grid> */}
          
            {/* </Grid> */}
          </Grid>
        </ThemeProvider>
      </Layout>
    </>
  );
}

export default Dashboard;
