import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../../Assets/icons/search.png";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: "30px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  marginLeft: "30px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      // width:'70%'
    },
  },
}));

export default function PrimarySearchBar({ value, onChange }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search style={{display:"flex"}}>
     <div className="icon"  style={{ marginTop: "6px",marginRight:"8px" }}>
     <img
           
            src={SearchIcon}
            alt=""
          />
     </div>
         
        <input
          placeholder="search"
          inputProps={{ "aria-label": "search" }}
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "22px",
            border: "none",
            outline: "none",
            color: "#817E7E",
          }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Search>
    </Box>
  );
}
