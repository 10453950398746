import React from "react";
import "./index.css";
function PermisionSwitch({ name, value, onChange }) {
  return (
    <>
      {/* <input
        name={name}
        checked={value ? true : false}
        onChange={(e) => onChange(e)}
        id={"checkbox---custom"}
        type={"checkbox"}
      
      /> */}
      <label className="custom-checkbox">
      <input
       name={name}
        type="checkbox"
        checked={value ? true : false}
        onChange={(e) => onChange(e)}
      />
      <span className="checkmark"></span>
    </label>
    </>
  );
}

export default PermisionSwitch;
