import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PlusIcon from "../../../../Assets/icons/Vector1.png"
import {
  addScreenToPlaylist,
  deletePlaylist,
  movePlaylistToFolder,
  playlistScreenCheck,
  playlistToMultipleScreen
} from "../../../../Redux/Actions/playlistAction";
import { getScreens } from "../../../../Redux/Actions/screenAction";
import "./playlistcard.css";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
// import { ReactComponent as CreateIcon } from "../../../../Assets/images/createicon.svg";
import { Box,Dialog,Button,TextField, DialogTitle, Menu, MenuItem, DialogContent } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Close,
  DeleteOutlineOutlined,
  DesktopMacOutlined,
  Folder,
  PlaylistAddOutlined,
} from "@mui/icons-material";
import Modal from "../playlistmodel/playlistmodel";
import PlaylistContentCardItem from "../content-card/PlaylistContentCardItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useFilter from "../../../../hooks/useFilter";
import MiniLoader from "../../../../components/loader/MiniLoader";
import MoveFolder from "../../../../components/models/MoveFolder";
import Model from "../playlistmodel/playlistmodel"
function PlaylistCard(props) {
  const dispatch = useDispatch();
  const { screens, isPlaylistAssign, playlist ,user,isPlaylistMoving,isPlaylistUpdated} = useSelector(
    (state) => ({
      screens: state.Screen.screens,
      isPlaylistAssign: state.Playlist.isPlaylistAssign,
      isPlaylistUpdated:state.Playlist.isPlaylistUpdated,
      playlist: state.Playlist.playlist.find((p) => p._id == props.play._id),
      isPlaylistMoving:state.Playlist.isPlaylistMoving,
      user:state.User.user
    }),
    shallowEqual
  );
  const [code, setCode] = useState("");
  const [playlistId,setPlaylistId]=useState('')
  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [screenMacs, setScreenMacs] = useState([]);
  const [filteredScreenMacs,setFilterScreenMacs]=useState([]);
  const [unchecked,setUnchecked]=useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const open = Boolean(anchorEl);
  const [isOpenFolderModel, setIsOpenFolderModel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [_folderId,setFolderId]=useState('');
  const filtered=useFilter(searchVal,screenMacs);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);]
  };
  useEffect(() => {
    if (isPlaylistUpdated) {
      if (unchecked.length > 0) {
        for (let m of unchecked) {
          props.socketRef.current.emit("event:delegate", {
            detail: { mac: m },
          });
        }
        setUnchecked([]);
      }
      dispatch(getScreens(user?._id));
      setIsScreenOpen(false);
      _copyingScreens();
      //  dispatch(playlistFlagOff())
    }
  }, [isPlaylistUpdated]);
  
  useEffect(() => {
    dispatch(getScreens(user?._id))
    // console.log(props);
  }, []);
  useEffect(() => {
   _copyingScreens();
  }, []);
  const _copyingScreens=()=>{
    let _screens = [];
    screens.forEach((s) => {
      _screens.push({ _id: s._id, name: s.name, mac: s.mac,_playlistId:s?.playlist?._id,playlistName:s?.playlist?.name, isChecked: false });
    });
    setScreenMacs(_screens);
  }
  // useEffect(()=>{
  //   setScreenMacs(filteredScreenMacs);
  // },[searchVal])
  // useEffect(()=>{
  //   let _macs=[]
  //  playlist.mac_list.forEach(m=>{
  //    _macs.push()
  //  })
  // },[props.play._id,isPlaylistAssign])
  const newCheck = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        props.socketRef.current?.emit("playlist_added", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  const onGetScreenAssign = (e,position) => {
    const { value, checked, name } = e.target;
    // if(position=='unfiltered'){
      if (checked) {
        setScreenMacs((prev) => [
          ...prev.map((s) => (s.name == name ? { ...s, isChecked: true } : s)),
        ]);
        
        setUnchecked(prev=>[...prev.filter(m=>m!==value)])
      } else {
        setScreenMacs((prev) => [
          ...prev.map((s) => (s.name == name ? { ...s, isChecked: false } : s)),
        ]);
        setUnchecked((prev)=>[...prev,value])
      }
    // }
  };
  const onAssignPlaylist = async () => {
    try {
      let mac_list = [];
      for (let i in filtered) {
        if (filtered[i].isChecked) {
          mac_list.push(filtered[i].mac);
        }
      }
      // console.log(filtered, mac_list)
      dispatch(playlistToMultipleScreen(props.play._id, mac_list,unchecked));
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSearchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
  };
  const searchCallBack=useCallback(onSearchFilter,[searchVal])

  const onOpenAssignScreen=(id)=>{
    // console.log(screenMacs)
    const _checkedList=screenMacs.map(s=>s._playlistId==id ? {...s,isChecked:true} : s);
    console.log('selected->',_checkedList)
    setScreenMacs(_checkedList)
    setIsScreenOpen(true);
  }

  const onOpenFolderModel=id=>{
   setPlaylistId(id);
    setIsOpenFolderModel(true);
  }
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
    <div className="nameandactions" style={{display:"flex",alignItems:"center",width:"77vw",padding:"0px",justifyContent:"space-between",borderBottom:"1px solid #CFCFCF",marginLeft:"4px",marginBottom:"10px",marginTop:"10px",padding:"10px 0px"}}>
    <div className="nameofplaylist" style={{color:"#7B7777" ,fontFamily:"'Poppins', sans-serif",fontWeight:"400",fontSize:"14px", cursor:"pointer"}}
     onClick={openModal}
     >{props.play.name}</div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="topSecModel">
        <div className="nameofplaylist">
       <h4> {props.play.name}</h4>
        </div>
        <div className="wrap">
          
        <div className="buttons">
          <button style={{color:" var(--primary-color)",background:"white",border:"1px solid var(--primary-color) ",borderRadius:"8px",height:"28px",width:"50px"}}
            onClick={() => {
              closeModal();
              handleClose();
              props.deleteWholePlaylist(
                props.play?._id,
                props.play?.activescreen?.mac
              );
              
            }}>delete</button>
          <button style={{color:"white",background:" var(--primary-color)",border:"1px solid var(--primary-color) ",borderRadius:"8px",height:"28px",width:"50px"}}>Edit</button>
        </div>
           <button className="closebtn"
                        onClick={closeModal}
                        style={{background:" white",border:"none",borderRadius:"8px",height:"28px",width:"30px",marginTop:"7px",cursor:"pointer"}}
                      >
                        <CloseIcon />
                      </button>
        </div>
        </div>
        <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // background: '#f0f0f0',
                        background: "white",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        height: "35px",
                        border: "1px solid rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <SearchIcon
                        style={{ color: "#888", marginRight: "8px" }}
                      />
                      <input
                        type="text"
                        
                        placeholder="Search"
                        style={{
                          border: "none",
                          outline: "none",
                          background: "transparent",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      />
                    </div>
                    <div className="nameofplaylist" style={{borderBottom:"1px solid rgba(0,0,0,0.2"}}>
       <h4>Files</h4>

       <button style={{color:"white",background:" var(--primary-color)",border:"1px solid var(--primary-color) ",borderRadius:"8px",height:"36px",width:"81px",marginTop:"15px",fontSize:"18px"}}
         onClick={() =>
          props.addContentModel(
            props.play?._id,
            props.play?.activescreen?.mac
          )
        }
       > <img src={PlusIcon} alt="" /> Add</button>
        </div>
 <div className="aplaylist-cards">
        <div className="aplaylist-cards-top">
          <div className="aplaylist-schedule-icon flex justifyContentCenter alignItemCenter"></div>
          <div className="aplaylist-card-body">
          
              {props.play.content.map((v, index) => (
                <PlaylistContentCardItem
                  key={index}
                  content={v}
                  deleteContent={props.deleteContent}
                  addSchedule={props.addSchedule}
                  updateTimeHandler={props.updateTimeHandler}
                  playID={props.play._id}
                 
                />
              ))}
           
          </div>
        </div>
   
      </div>

      </Modal>
    <div className="menuitems"  style={{display:"flex",alignItems:"center"}}>
    <div style={{margin:"0 10px"}}>
              <PlaylistAddOutlined
              style={{ color:"#8C9396", cursor: "pointer" , height:"18px",width:"18px"}}
              onClick={() =>
                props.addContentModel(
                  props.play?._id,
                  props.play?.activescreen?.mac
                )
              }
            /></div>
    <div style={{margin:"0 10px"}}
               disabled={
                user.role == "user"
                  ? user?.previlliages?.playlist?.canDelete
                    ? false
                    : true
                  : false
              }
                onClick={() => {
                  handleClose();
                  props.deleteWholePlaylist(
                    props.play._id,
                    props.play?.activescreen?.mac
                  );
                }}
             
              >
                <DeleteOutlineOutlined
                  sx={{color:"#8C9396", cursor: "pointer", height:"18px",width:"18px" }}
                />
              </div>
              <div style={{margin:"0 10px"}}
               disabled={
                user.role == "user"
                  ? user?.previlliages?.playlist?.canEdit
                    ? false
                    : true
                  : false
              }
                onClick={() => {
                  handleClose();
                  onOpenAssignScreen(props.play._id)
                 
                }}
              
              >
                <DesktopMacOutlined 
                sx={{color:"#8C9396", cursor: "pointer", height:"18px",width:"18px" }}
                />
                
              </div>
              <div style={{marginLeft:"10px"}}
              onClick={()=>{
                handleClose();
                onOpenFolderModel(props.play._id)}}
               
              >
                <Folder
                  sx={{ color:"rgba(168, 176, 180, 1)", cursor: "pointer" , height:"18px",width:"18px" }}
                />
              </div>
             
    </div>
    </div>
   
      <MoveFolder
        isOpen={isOpenFolderModel}
        onClose={() => setIsOpenFolderModel(false)}
        _folders={props._folders}
        onGetFolderId={(e)=>setFolderId(e)}
        isMoving={isPlaylistMoving}
        moveMethod={movePlaylistToFolder(playlistId,_folderId)}
      />

      <Dialog
        open={isScreenOpen}
        maxWidth={"sm"}
        onClose={() => setIsScreenOpen(false)}
        sx={{
          zIndex: "77777777777777700000000000",
        }}
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          <span>Assign to screens</span>{" "}
          <Close
            onClick={() => setIsScreenOpen(false)}
            sx={{ color: "red", cursor: "pointer" }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={searchVal}
              onChange={searchCallBack}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
              // border:'1px solid black'
            }}
          >
            <Box sx={{width:'100%',height:'100%',display:'flex',justifyContent:'space-between'}}>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            >
              <input
                type={"checkbox"}
                onChange={(e) =>
                  e.target.checked
                    ? setScreenMacs((prev) => [
                        ...prev.map((s) => ({ ...s, isChecked: true })),
                      ])
                    : setScreenMacs((prev) => [
                        ...prev.map((s) => ({ ...s, isChecked: false })),
                      ])
                }
                style={{ height: "20px", width: "20px" }}
              />
              <span>Select all</span>
            </Box>
             <Box>Playlist</Box>
            </Box>
            

            {/* <Box sx={{width:'100%',height:'100%',display:'flex',justifyContent:'space-evenly'}}> */}
          {
          filtered.map((_, _index) => (
            <>
              <Box
              sx={{width:'100%',height:'100%',display:'flex',justifyContent:'space-between'}}
                // display={"flex"}
                // justifyContent={"flex-start"}
                // alignItems={"center"}
                // gap={"10px"}
              >
                <Box 
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={"10px"}
                >
                <input
                  type={"checkbox"}
                  name={_?.name}
                  value={_?.mac}
                  onChange={(e)=>onGetScreenAssign(e,'unfiltered')}
                  checked={_.isChecked}
                  // disabled={_?.playlistName!==undefined || _?.playlistName!=='' || _?.playlistName!==null ? _?.isChecked ? false : true : false}
                  style={{ height: "20px", width: "20px",cursor:'pointer' }}
                />
                <span>{_?.name}</span>
                </Box>
              <span style={{alignSelf:'flex-end'}}>{_?.playlistName}</span>
              </Box>
            </>
          ))
        }
        

            {/* </Box> */}
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                onAssignPlaylist();
              }}
              variant={"contained"}
              sx={{
                background: "var(--primary-color)",
                textTransform: "capitalize",
              }}
              className="multiple--assign--btn"
            >
              Assign
            </Button>
            {isPlaylistAssign && <MiniLoader />}
            {/* <span>Total Screens {screens.length}</span> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PlaylistCard;
