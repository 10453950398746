import React, { useState } from 'react';
import TextSnippetIcon from '../../Assets/icons/Group.png';
import PersonAddIcon from '../../Assets/icons/Group.svg';
import DesktopMacIcon from '../../Assets/icons/carbon_screen.svg';
import DesktopMacIcon1 from "../../Assets/icons/Component 1.svg"
import bellframe from "../../Assets/icons/Frame 1261154647.png"
import ListIcon from '../../Assets/icons/material-symbols-light_playlist-add.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import logout1 from  "../../Assets/icons/material-symbols-light_logout-sharp.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import mainlogo from "../../Assets/images/Salaar_Signage_Blue_Transparent 1.svg"
import './sidebar.css';
// import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
// import { ReactComponent as LogoutIcon } from "../../Assets/images/logoutIcon.svg";
import { logout } from '../../Redux/Actions/userAction';
import {useDispatch} from 'react-redux';
import { Button } from '@mui/material';
import {makeStyles} from '@material-ui/core';
import { LogoutOutlined } from '@mui/icons-material';
import DashboardIcon from '../../Assets/icons/layout-grid.svg';
const useStyle=makeStyles((theme)=>{
    return {
      text:{
          [theme.breakpoints.down('sm')]:{
            //   display:'none'
          }
      },
      sibarContainerMobile:{
        [theme.breakpoints.down('sm')]:{
            // width:'70px !important',
        }
    },
    sidebarUpperSecMobile:{
       
        [theme.breakpoints.down('sm')]:{
            display:'flex !important',
            justifyContent:'center !important',
            alignItems:'center !important',
            // width:'70px !important',
            // height:'20vh',
            '& img':{
                // width:'80px !important',
                // height:'80px !important'
            }
        }
    }
    }
})
function Sidebar({ user }) {
    const location=useLocation();
    const classes=useStyle();
    const [gridView, setGridView] = useState(true)
    const dispatch=useDispatch();
    const navigate=useNavigate();
const logoutHandler=()=>{
    dispatch(logout())
 return navigate('/login')
}
console.log(location.pathname)
    return (
        <>
            <div className={`sidebar-container ${classes.sibarContainerMobile}`}>
                <div className={`sidebar-upper-sec ${classes.sidebarUpperSecMobile}`} >
                   <div className="topsec">
                   <img src={mainlogo} />
                   </div>
                </div>
                <div className="sidebar-middle-sec">
                    <div className="side-bar-box" >
                        <div className="links"
                         style={{border:location.pathname==='/' ? "1px solid var(--primary-color)" : ''}}
                         >
                            <div className='link-icon'>
                                <img    src={DashboardIcon} alt=""  style={{
        filter: location.pathname === '/' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "none",
      }}/>
                   </div>
                            <Link
                            style={{color:location.pathname==='/' ? "var(--primary-color)" : '',fontSize:"16px"}}
                            className={classes.text} onClick={() => setGridView(false)} to='/'>Dashboard</Link>
                        </div>

                        <div className="links"  style={{border:location.pathname==='/content' ? "1px solid var(--primary-color)" : ''}}>
                            <div className='link-icon'>
                                <img src={TextSnippetIcon} alt=""  style={{
        filter: location.pathname === '/content' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "",
      }} />
                              
                            </div>
                            <Link className={classes.text} style={{
                                color:location.pathname==='/content' ? "var(--primary-color)" : '',fontSize:"16px"
                            }} onClick={() => setGridView(false)} to="/content">Content</Link>
                        </div>
                        {
                            user.role === 'admin' &&
                            <div className="links"  style={{border:location.pathname==='/user' ? "1px solid var(--primary-color)" : ''}}>
                                <div className='link-icon'>
                                   <img src={PersonAddIcon} alt=""  style={{
        filter: location.pathname === '/user' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "none",
      }}/>
                                </div>
                                <Link className={classes.text} 
                                style={{color:location.pathname==='/user' ? "var(--primary-color)" : '',fontSize:"16px"}}
                                onClick={() => setGridView(false)} to="/user">User</Link>
                            </div>
                        }
                         <div className="links" style={{border:location.pathname==='/playlist' ? "1px solid var(--primary-color)" : ''}}>
                            <div className='link-icon'>
                               <img src={ListIcon} alt="" style={{
        filter: location.pathname === '/playlist' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "",
      }}/>
                            </div>
                            <Link className={classes.text} 
                            style={{color:location.pathname==='/playlist' ? "var(--primary-color)" : '',fontSize:"16px"}}
                            onClick={() => setGridView(false)} to="/playlist">Playlist</Link>
                        </div>
                        <div className="links"  style={{border:location.pathname==='/screen' ? "1px solid var(--primary-color)" : ''}}>
                            <div className='link-icon'>
                              <img src={DesktopMacIcon} alt="" style={{
        filter: location.pathname === '/screen' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "",
      }}/>
                            </div>
                            <Link className={classes.text} 
                            style={{color:location.pathname==='/screen' ? "var(--primary-color)" : '',fontSize:"16px"}}
                            onClick={() => setGridView(false)} to="/screen">Screen</Link>
                        </div>
                       <div className="links"   style={{border:location.pathname==='/setting' ? "1px solid var(--primary-color)" : ''}}>
                       <div className='link-icon'>
                              <img src={DesktopMacIcon1} alt="" 
                              style={{
        filter: location.pathname === '/setting' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "",
      }}
                            />
                            </div>
                            <Link className={classes.text} 
                            style={{color:location.pathname==='/setting' ? "" : '',fontSize:"16px"}}
                            onClick={() => setGridView(false)} to="/setting">Setting</Link>
                       </div>
                        <div className="links">
                            <div className='link-icon'>
                                {/* <LogoutOutlined className={'link-a-color'} /> */}
                                <img src={logout1} alt="" style={{
        filter: location.pathname === '/screen' ? "brightness(0) saturate(100%) invert(29%) sepia(84%) saturate(2143%) hue-rotate(185deg) brightness(93%) contrast(92%)" : "",
      }}/>
                            </div>
                            <button className={classes.text} style={{
                                border:'none',
                                outline:'none',
                                background: (!gridView ? '#F3F0FF' : 'white'),
                                cursor:'pointer',
                                color: '#B8B8B8',fontSize:"16px"

                            }} onClick={logoutHandler}>Logout</button>
                        </div>
                        
              <div className="bell">
                
                <img src={bellframe} alt="" />
                
              </div>
              <div className="sidebarbottom"  >
              <div style={{color:"rgba(0,0,0,0.3)",marginTop:"16px",marginRight
                :"5px",fontSize:"10px",display:"flex",alignItems:"center",justifyContent:"center"}}>enable notification for you</div>
              <div style={{fontWeight:"bold",margin:"4px",fontSize:"12px",marginRight
                :"5px",display:"flex",alignItems:"center",justifyContent:"center"}}>Salarsynic</div>
              <button className='btn-btn-primery'>Active</button>
              </div>
              
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar