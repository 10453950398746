import { DeleteOutlineOutlined, EditOutlined, Folder } from "@mui/icons-material";
import React, { useState } from "react";
import folder from "../../Assets/images/flat-color-icons_folder.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRouter } from "../../Redux/Actions/contentAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import "./folder.css";
import { Edit } from "lucide-react";
function FolderComponent({ index, _folder, _module,onEdit, onDeleteFolder }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  const onRouter = (id) => {
    navigate(`/${_module}/${id}`);
    // dispatch(getRouter(id));
  };
  const truncateName = (name) => {
    return name.length > 6 ? name.slice(0, 6) + ".." : name;
  };
  return (
    <>
      {
        <div
          onDoubleClick={() => onRouter(_folder._id)}
          key={index}
          className="folder"
          style={{
            background: "white",
            color: "black",
            border: "none",
            zIndex: "1",
            marginRight:"5px",
            marginLeft:"2px"
          }}
        >
          <MoreVertIcon 
           style={{
            position: "absolute", // Position the icon absolutely within the container
            top: "5px", // Adjust the vertical position
            right: "5px", // Adjust the horizontal position
            cursor: "pointer", // Optional: change the cursor to indicate clickability
          }}
          onClick={handleClick}
          aria-controls={open ? "content-menu" : undefined}
          aria-haspopup={"true"}
          aria-expanded={open ? "true" : undefined}
          id="content-menu-btn"
          />
            <Menu
            // sx={{ zIndex: "10000000" }}
            id={"content-menu"}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "content-menu-btn",
            }}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Edit
                onClick={() => onEdit(_folder?._id)}
                style={{ color: "var(--primary-color)" }}
              />
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteOutlineOutlined
              onClick={()=>onDeleteFolder(_folder?._id)}
              sx={{ color: "var(--primary-color)" }} />
            </MenuItem>
          </Menu>
          <div className="image" style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={folder} alt="folder" /></div>
          
          <span  className="foldername" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>{truncateName(_folder?.name)}</span>
          {/* <EditOutlined
            className='edit-icon'
          /> */}
        </div>
      }
    </>
  );
}

export default FolderComponent;
