import React,{useState} from "react";
import AppWidgetForm from "../../component/models/AppWidgetForm"; // Import modal component
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { DialogActions, Button } from "@mui/material";
import frame1 from "../../../../Assets/images/Frame1.png";
import frame4 from "../../../../Assets/images/Frame 4.png";
import LayoutChoose from "./LayoutChoose";
import "./Channel.css";
import SuccessDone from "./SuccessDone";

const Layout = ({ isOpen, onClose }) => {
  const [isSuccessDone, setisSuccessDone] = useState(false);
  const [isLayoutChooseOpen, setIsLayoutChooseOpen] = useState(false); // New state for layout popup


  const handleOpenLayoutChoose = () => {
    setIsLayoutChooseOpen(true);
  };

  const handleCloseLayoutChoose = () => {
    setIsLayoutChooseOpen(false);
  }
  const handleOpenModal = () => {
    setisSuccessDone(true);
     
     
    };
  
    const handleCloseModal = () => {
      setisSuccessDone(false);
    };
  return (
    <>
      <AppWidgetForm
        isOpen={isOpen}
        onClose={onClose}
        style={{ height: "60vh", width: "40vw" }}
      >
        <div className="channelmainname">
          <span onClick={onClose}>
            <KeyboardArrowLeftIcon />
          </span>
        </div>
        <div className="layoutmodelinpput">
          <div className="wraplabelandtext">
            <span>Channel Name</span>
            <div className="inputlayoutmodel">
              <input
                id="outlined-basic"
                label="Your Name Here"
                placeholder="Channel Name"
                variant="outlined"
                fullWidth
              />
              <Button
                style={{
                  color: "white",
                  background: "var(--primary-color)",
                  borderRadius: "8px",
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "10px",
                  marginLeft: "15px",
                }}
                onClick={handleOpenLayoutChoose}
              >
                Select Layout
              </Button>
            </div>
          </div>
        </div>
        <div className="dyanmic-playlist-sel-prev">
          <div className="dyanmic-playlist-sel">
            <div className="contentdyanmic-playlist-sel1">
              <span className="content">Mian layout</span>
              <span className="playlist">
                Playlist: <span className="none">none</span>
              </span>
              <Button
                style={{
                  color: "white",
                  background: "var(--primary-color)",
                  borderRadius: "8px",
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "10px",
                  width: "99px",
                  height: "28px",
                  marginLeft: "15px",
                }}
              >
                Add Playlist
              </Button>
            </div>
            <div className="contentdyanmic-playlist-sel2">
              <span className="content">Mian + Right layout</span>
              <span className="playlist">
                Playlist: <span className="none">none</span>
              </span>
              <Button
                style={{
                  color: "white",
                  background: "var(--primary-color)",
                  borderRadius: "8px",
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "10px",
                  width: "99px",
                  height: "28px",
                  marginRight: "5px",
                }}
              >
                Add Playlist
              </Button>
            </div>
          </div>
          <div className="dyanmic-playlist-prev">
            <span className="preview">Preview</span>
            <div className="main-box">
              <div className="textandbox">
                <div className="box">
                  <img src={frame1} alt="" />
                </div>
                <span>Main</span>
              </div>
              <div className="textandbox">
                <div className="box">
                  <img src={frame4} alt="" />
                </div>
                <span>Mian + Right Bar</span>
              </div>
            </div>
          </div>
        </div>
        <div className="cancleandsavebtns">
          <Button style={{width:"100px",height:"30px", color:"var(--primary-color)",border:"1px solid var(--primary-color)"}}>Close</Button>
          <Button style={{width:"100px",height:"30px", color:"white",border:"1px solid var(--primary-color)",background:"var(--primary-color)"}} onClick={handleOpenModal}>Save</Button>
        </div>
      </AppWidgetForm>
      {isLayoutChooseOpen && (
        <LayoutChoose isOpen={isLayoutChooseOpen} onClose={handleCloseLayoutChoose} />
      )}
      <SuccessDone isOpen={isSuccessDone} onClose={handleCloseModal}/>
    </>
  );
};

export default Layout;
