import {
  GET_CONTENT_SUCCESS,
  DELETE_CONTENT_REQUEST_SUCCESS,
  CHECK_ALL_CONTENT,
  CHECK_ONE_CONTENT,
  DELETE_ALL_CONTENT,
  UNCHECK_ONE_CONTENT,
  UNCHECK_ALL_CONTENT,
  ADD_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST,
  GET_CONTENT_REQUEST,
  ADD_CONTENT,
  GET_CONTENT_FAILED,
  CONTENT_FLAG_OFF,
  SEARCHING,
  SEARCHING_END,
  CONTENT_SCHEDULE_SUCCESS,
  CONTENT_SCHEDULE_DELETE,
  CLIENT_CONTENT_SUCCESS,
  CONTENT_UPDATED,
  ROUTER_FOLDER_SUCCESS,
  MOVE_TO_FOLDER,
  MOVE_TO_FOLDER_REQUEST,
  GET_CONTENTS_BY_FOLDER,
} from "../constant";

const initial = {
  isContentLoading: false,
  isDeleting: false,
  isDeleted: false,
  isContentAdding: false,
  isContentAdded: false,
  isContentScheduled: false,
  isContentScheduledDeleted: false,
  isContentLoaded: false,
  isRouted: false,
  isContentMoving: false,
  isContentMoved: false,
  content: [],
  contentByFolder:[],
  contents: [],
  clientContent: [],
};
export const ContentReducer = (state = initial, action) => {
  switch (action.type) {
    case CONTENT_FLAG_OFF:
      return {
        ...state,
        isContentAdded: false,
        isContentScheduled: false,
        isContentScheduledDeleted: false,
        isDeleted: false,
        isDeleting: false,
        isContentLoaded: false,
        isRouted: false,
        isContentMoving: false,
        isContentMoved: false,
      };
    case ADD_CONTENT_REQUEST:
      return {
        ...state,
        isContentAdding: true,
      };
    case ADD_CONTENT:
      return {
        ...state,
        isContentAdding: false,
        isContentAdded: true,
        contentByFolder:[...state.contentByFolder,{...action.payload,checked:false}],
        contents: [...state.contents, { ...action.payload, checked: false }],
        // content: [...state.content, { ...action.payload, checked: false }],
      };
    case GET_CONTENT_REQUEST:
      return {
        ...state,
        isContentLoading: true,
      };
    case GET_CONTENT_SUCCESS:
      let contentArr = [];
      action.payload.forEach((c) => contentArr.push({ ...c, checked: false }));
      return {
        ...state,
        isContentLoading: false,
        isContentLoaded: true,
        contents: contentArr,
        // content: contentArr.filter((c) => c.folderId === "Home"),
      };
      case GET_CONTENTS_BY_FOLDER:
        let _contentArr = [];
        action.payload.forEach((c) => _contentArr.push({ ...c, checked: false }));
        return {
          ...state,
          isContentLoading: false,
          isContentLoaded: true,
          contentByFolder: _contentArr,
          // content: contentArr.filter((c) => c.folderId === "Home"),
        };
    case GET_CONTENT_FAILED:
      return {
        ...state,
        isContentLoading: false,
      };
    case DELETE_CONTENT_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        content: state.content.filter((con) => con.id !== action.payload),
      };
    case CHECK_ALL_CONTENT:
      let checkAllContentArr = [];
      state.content.forEach((c) =>
        checkAllContentArr.push({ ...c, checked: true })
      );
      return {
        ...state,
        content: checkAllContentArr,
      };
    case UNCHECK_ALL_CONTENT:
      let unCheckAllContentArr = [];
      state.content.forEach((c) =>
        unCheckAllContentArr.push({ ...c, checked: false })
      );
      return {
        ...state,
        content: unCheckAllContentArr,
      };
    case CHECK_ONE_CONTENT:
      const checkOneContentIndex = state.content.findIndex(
        (c) => c.id === action.payload
      );
      state.content[checkOneContentIndex] = {
        ...state.content[checkOneContentIndex],
        checked: true,
      };
      return {
        ...state,
        content: state.content,
      };
    case UNCHECK_ONE_CONTENT:
      const unCheckOneContentIndex = state.content.findIndex(
        (c) => c.id === action.payload
      );
      state.content[unCheckOneContentIndex] = {
        ...state.content[unCheckOneContentIndex],
        checked: false,
      };
      return {
        ...state,
        content: state.content,
      };
    case DELETE_ALL_CONTENT:
      let deleteContentArr = [];
      action.payload.forEach((c) =>
        deleteContentArr.push({ ...c, checked: false })
      );
      return {
        ...state,
        content: deleteContentArr,
      };
    case CONTENT_SCHEDULE_SUCCESS:
      state.content[
        state.content.findIndex((c) => c.id === action.payload._id)
      ] = { ...action.payload };
      return {
        ...state,
        isContentScheduled: true,
        content: state.content,
      };
    case CONTENT_SCHEDULE_DELETE:
      state.content[
        state.content.findIndex((c) => c.id === action.payload._id)
      ] = { ...action.payload };
      return {
        ...state,
        isContentScheduledDeleted: true,
        content: state.content,
      };
    case CLIENT_CONTENT_SUCCESS:
      return {
        ...state,
        clientContent: action.payload,
      };
    case CONTENT_UPDATED:
      const __findIndexForContentUpdate = state.content.findIndex(
        (c) => c.id === action.payload.id
      );
      state.content[__findIndexForContentUpdate] = { ...action.payload };
      return {
        ...state,
        content: state.content,
      };
    case ROUTER_FOLDER_SUCCESS:
      // console.log('router-roducer-id->',action.payload,state.contents)
      console.log(
        "router-reducer=>",
        state.contents.filter((c) => c.folderId === action.payload)
      );
      return {
        ...state,
        isRouted: true,
        content: [
          ...state.contents.filter((c) => c.folderId == action.payload),
        ],
      };
    case MOVE_TO_FOLDER_REQUEST:
      return {
        ...state,
        isContentMoving: true,
      };
    case MOVE_TO_FOLDER:
      const __movedIndex = state.contents.findIndex(
        (c) => c.id == action.payload.id
      );
      state.contents[__movedIndex] = { ...action.payload };
      return {
        ...state,
        isContentMoved: true,
        content: state.content.filter((c) => c.id !== action.payload.id),
      };
    default:
      return state;
  }
};
