import React from 'react'
import "./dashboard.css";
import { Grid } from "@mui/material";

import screenblue from "../../Assets/icons/bluescreen.svg";
import screengreen from "../../Assets/icons/greenscreen.svg";
import screenred from "../../Assets/icons/redscreen.svg";
import pathblue from "../../Assets/icons/bluepath.svg";
import pathgreen from "../../Assets/icons/green path.svg";
import pathred from "../../Assets/icons/redpath.svg";
const DashboardScreenCount = () => {
  return (
    <>
      <Grid>
              <div className="screenmain">
                <div className="screentext">Screen</div>
                <div className="mainbox">
                  <div className="boxes">
                    <div className="wrap">
                      <div className="text">
                        <span className="text1">Total Screen </span>
                        <span className="numb">03</span>
                      </div>
                      <div className="imgscreen" style={{background:"hsla(209, 71%, 94%, 1)"
                        }}>
                        <img src={screenblue} alt="" />
                      </div>
                    </div>
                    <div className="lowertext">
                      <img src={pathblue} alt="" />{" "}
                      <span style={{ color: "hsla(209, 99%, 65%, 1)" }}>
                        8.5%
                      </span>{" "}
                      up from yesterday
                    </div>
                  </div>
                  <div className="boxes">
                    <div className="wrap">
                      <div className="text">
                        <span className="text1">Online Screen </span>
                        <span className="numb">03</span>
                      </div>
                      <div className="imgscreen"  style={{background:" hsla(143, 87%, 93%, 1)"
                        }}>
                        <img src={screengreen} alt=""/>
                      </div>
                    </div>
                    <div className="lowertext">
                      <img src={pathgreen} alt="" />
                      <span style={{ color: " hsla(143, 53%, 50%, 1)" }}>
                         8.5%
                      </span>
                        up from yesterday
                    </div>
                  </div>
                  <div className="boxes">
                    <div className="wrap">
                      <div className="text">
                        <span className="text1">Offline Screen </span>
                        <span className="numb">03</span>
                      </div>

                      <div className="imgscreen"  style={{background:" hsla(0, 100%, 92%, 1)"
                        }}>
                        <img src={screenred} alt="" />
                      </div>
                    </div>
                    <div className="lowertext">
                      <img src={pathred} alt="" />{" "}
                      <span style={{ color: " hsla(14, 81%, 46%, 1)" }}>
                        8.5%
                      </span>
                      up from yesterday
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
    </>
  )
}

export default DashboardScreenCount