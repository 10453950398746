import { DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./weatherwedgit.css";
import img from "../../../../Assets/images/Rainy.jpg";
import icon from "../../../../Assets/icons/cloud.png";
import sunIcon from '../../../../Assets/icons/sun.png';
import WeatherGlassmorph from "../../component/WeatherThemes/Theme-1/WeatherGlassmorph";
import AutoSuggestion from "../../component/AutoSuggestion";
import cities from '../../../../api/cities500.json';
import axios from "axios";
import clouds from '../../../../Assets/images/Cloudy.jpg';
import Clear from '../../../../Assets/images/Clear.jpg'
import Haze from '../../../../Assets/images/Sunny.jpg'
import weatherlogo from "../../../../Assets/images/Group 667.png"
import CloseIcon from '@mui/icons-material/Close';

function WeatherWidget({ onClose,folderId }) {
  const [city,setCity]=useState('')
  const [weatherData,setWeatherData]=useState({});
  console.log('weather data state', JSON.stringify(weatherData,))
  const bgDictionary={
       Clouds:clouds,
       Clear,
       Haze
  }
  
  useEffect(()=>{
    if(city=='') return;
    getForcast({city:`${city}`,unit:'metric',appId:'637cd51f54778ca88339846c4d0caaa7',hostName:'https://api.openweathermap.org'});
  },[city])
  const getForcast=async (obj)=>{
     axios.get(
      `${obj.hostName}/data/2.5/weather?q=${obj.city}&units=${obj.unit}&APPID=${obj.appId}`
    ).then(res=>{
      // console.log('first-',res)
      setWeatherData({name:res.data?.name,temp:res.data?.main?.temp,wind:res.data?.wind?.speed,humidity:res.data?.main?.humidity,weather:res.data?.weather[0]?.main,icon:res.data.weather[0].icon})
      axios.get(
        `${obj.hostName}/data/2.5/forecast?lat=${res.data.coord.lat}&lon=${res.data.coord.lon}&units=${obj.unit}&APPID=${obj.appId}`
      ).then(resp=>{
        const filteredForecast = filterForecastByFirstObjTime(resp.data?.list);
        setWeatherData((prev)=>({...prev,list:filteredForecast}))
        // console.log(resp);
      })
    });
    // const request = await axios.get(
    //   `${obj.hostName}/data/2.5/forecast?lat=${obj.lat}&lon=${obj.lon}&units=${obj.unit}&APPID=${obj.appId}`
    // )
    // const response = await request.data;
  }
  const filterForecastByFirstObjTime = (forecastData) => {
    if (!forecastData) {
      return [];
    }

    const firstObjTime = forecastData[0].dt_txt.split(" ")[1];
    let filtered= forecastData.filter((data) => data.dt_txt.endsWith(firstObjTime));
    setWeatherData((prev)=>({...prev,list:filtered}))
    return filtered;
  };

// console.log(filteredForecast)
  return (
    <>
   <div className="Closebtnweather" onClick={onClose}>
  <button><CloseIcon /></button> 
   </div>
   <div className="weatherlogo">
    <img src={weatherlogo} alt="" />
   </div>
   <div className="inputs">
    <div className="nameinput">
      <label htmlFor="name">Name*</label> 
      <AutoSuggestion  placeholder="Search city by Name..." data={cities} onGetValue={(e)=>setCity(e)}  />
    </div>
    <div class="nameinput">
  <label for="theme">Select Theme*</label>
  <select id="theme" >
    <option value="black">Black</option>
    <option value="white">White</option>
  </select>
</div>
<div class="nameinput">
  <label for="datetime">Date And Time Setting*</label>
  <select id="datetime" name="datetime">
    <option
    //  disabled selected
    >Date And Time Setting</option>
    <option value="2024-10-15T08:30">2024-10-15 08:30 AM</option>
    <option value="2024-10-15T12:00">2024-10-15 12:00 PM</option>
    <option value="2024-10-15T16:30">2024-10-15 04:30 PM</option>
  </select>
</div>

<div class="nameinput">
  <label for="location">Location*</label>

  <select id="location" name="location">
    <option 
    // disabled selected
    >Location</option>
    <option value="new-york">New York</option>
    <option value="los-angeles">Los Angeles</option>
    <option value="chicago">Chicago</option>
    <option value="san-francisco">San Francisco</option>
  </select>
</div>
   </div>
   {weatherData.name && ( 
        <div>
          <WeatherGlassmorph weather={weatherData} />
        </div>
      )}
     
    </>
  );
}

export default WeatherWidget;
