import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { _EditFolder, CreateFolder } from "../../Redux/Actions/folderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function FolderModel({
  open,
  onClose,
  module,
  parent,
  userId,
  isEditMode,
  folderId,
}) {
  const { users, _folders } = useSelector(
    (state) => ({
      users: state.User.users,
      _folders: state.Folder.folders.find((f) => f._id == folderId),
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  // const [predecessor, setPredecessor] = useState([]);
  // const [delList, setDelList] = useState([]);
  // const [user, setUser] = useState([]);
  useEffect(() => {
    if (isEditMode) {
      setFolderName(_folders?.name);
      // let __user = [];
      // let ___user = {};
      // _folders?.predecessor?.forEach(({ user }) => {
      //   ___user[user._id] = user.username;
      // });
      // users.forEach((user) => {
      //   if (___user[user?._id] == user?.username) {
      //     __user.push({
      //       _id: user?._id,
      //       username: user?.username,
      //       role: user.role,
      //       isAssigned: true,
      //     });
      //   } else {
      //     __user.push({
      //       _id: user._id,
      //       username: user.username,
      //       role: user.role,
      //       isAssigned: false,
      //     });
      //   }
      // });
      // // const _pre_ids = __user.filter.((u) => u.isAssigned == true);
      // setPredecessor(_pre_ids.map((u) => u._id));
      // setUser(__user);
    } else {
      // setUser(
      //   users.map((u) => ({
      //     _id: u._id,
      //     username: u.username,
      //     role: u.role,
      //     isAssigned: false,
      //   }))
      // );
    }
  }, [isEditMode]);
  const onSubmitFolder = () => {
    if (isEditMode) {
      dispatch(_EditFolder(folderId, { name: folderName }));
    } else {
      dispatch(
        CreateFolder({
          name: folderName,
          module,
          parent,
          userId,
        })
      );
    }
    onClear();
  };
  const onClear = () => {
    onClose();
    setFolderName("");
    // setPredecessor([]);
    // setUser([]);
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>Create Folder</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                label="Title"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={onSubmitFolder}
                // onClick={() => {
                //   dispatch(
                //     CreateFolder({ name: folderName, module, parent, userId })
                //   );
                //   onClose();
                // }}
              >
                Create
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FolderModel;
