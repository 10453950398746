import React, { useState } from "react";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { imgUrl } from "../../../api/api";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Folder } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
function Image({ data, addSchedule, deleteSingleContent,onOpenFolderModel }) {
  const {user}=useSelector(state=>state.User)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
            <MoreVertIcon
             
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            />
            <Menu
              sx={{ zIndex: "100000002111" }}
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
              disabled={
                user?.role == "user"
                  ? user?.previlliages?.content?.canDelete
                    ? false
                    : true
                  : false
              }
                onClick={() => {
                  handleClose();
                  deleteSingleContent(data.id);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineIcon  />
              </MenuItem>
              <MenuItem
              onClick={()=>{
                handleClose();
                onOpenFolderModel(data.id)}}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Folder
                  sx={{ cursor: "pointer" }}
                />
              </MenuItem>
            </Menu>
          </div>
          <img src={`${data.url}`} alt="" />
        </div>
        <div className="content-bottom">
          <p>{data?.name?.split("--")[0]?.substring(0, 20)}</p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              <PhotoCameraBackIcon
                sx={{
                  fontSize: "17px",
                
                }}
              />
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Image;
