import { api } from "../../api/api";
import {
  CREATE_FOLDER_SUCCESS,
  DELETE_FOLDER_SUCCESS,
  GET_FOLDER_SUCCESS,
  UPDATE_FOLDER_SUCCESS,
} from "../constant";

export const CreateFolder = (obj) => {
  return async (dispatch) => {
    try {
      const { status, data } = await api.post("/folder/createfolder", obj);
      if (status == 201) {
        dispatch({ type: CREATE_FOLDER_SUCCESS, payload: data.folder });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const GetFolders = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.get(`/folder/getfolders/${id}`);
    if (status == 200) {
      dispatch({ type: GET_FOLDER_SUCCESS, payload: data.folders });
    }
  };
};

export const _GetFoldersByUser = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.get(`/folder/GetFoldersByUser/${id}`);
    if (status == 200) {
      dispatch({ type: GET_FOLDER_SUCCESS, payload: data.folders });
    }
  };
};
export const _EditFolder = (id, obj) => {
  return async (dispatch) => {
    const { status, data } = await api.patch(`/folder/editfolder/${id}`, obj);

    if (status == 201) {
      dispatch({
        type: UPDATE_FOLDER_SUCCESS,
        payload: data.updated,
      });
    }
  };
};
export const _DeleteFolder = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.get(`/folder/deletefolder/${id}`);
    if (status == 201) {
      dispatch({
        type: DELETE_FOLDER_SUCCESS,
        payload: id,
      });
    }
  };
};
export const _ContentFolderDelete = (id, selectedRow) => {
  return async (dispatch) => {
    const { status, data } = await api.post(
      `/content/deletecontentfolder/${id}`,
      { selectedRows: selectedRow }
    );
    if (status == 201) {
      dispatch({
        type: DELETE_FOLDER_SUCCESS,
        payload: id,
      });
    }
  };
};
