import AppWidgetForm from "../../component/models/AppWidgetForm";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { DialogActions, Button } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useDispatch, useSelector } from "react-redux";

import "./Channel.css";
import PlaylistSelectmodel from "./PlaylistSelectmodel";
const Channel = ({ onClose }) => {
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

  const handleOpenSecondModal = () => {
    setIsSecondModalOpen(true);
   
   
  };

  const handleCloseSecondModal = () => {
    setIsSecondModalOpen(false);
  };


  
  return (
    <>
      <div className="wrap" style={{ fontFamily: "Poppins, sans-serif" }}>
        <AppWidgetForm isOpen={true} onClose={onClose}>
          <div className="channelmainname">
            <span onClick={onClose}>
             <KeyboardArrowLeftIcon/>
            </span>
            <div className="channelmaintext">Create a Channel </div>
            <div className="bellowtext">Create Your Channel From bellow</div>
          </div>

          <div
            style={{
             
              display: "flex",
              flexDirection: "column",

              padding: 20,
              gap: 10,
              width: "740px",
            }}
          >
            <div
              className="wrap"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: " hsla(0, 0%, 0%, 1)",
                }}
              >
                Enter Channel Name
              </span>
              <input
                id="outlined-basic"
                label="Your Name Here"
                placeholder="Channel Name"
                variant="outlined"
                fullWidth
                style={{
                  height: "35px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  paddingLeft: "17px",
                  height: "40px",
                  width: "715px",
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: "740px",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              margin:"8px 16px"
            }}
          >
            <div className="btnn">
              <Button
                variant="contained"
                style={{
                  background: "none",
                  border: "1px solid var(--primary-color)",
                  color: "var(--primary-color)",
                  width: "139px",
                  height: "28px",
                  padding: 20,
                  borderRadius: "8px",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
                onClick={onClose}
              >
                Close
              </Button>
            </div>
            <div className="btnn">
              <Button
                variant="contained"
                style={{
                  background: "var(--primary-color)",
                  width: "139px",
                  height: "28px",
                  padding: 20,
                  borderRadius: "8px",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
                onClick={handleOpenSecondModal} 
              >
                Create Channal
              </Button>
            </div>
          </div>
        </AppWidgetForm>
        <PlaylistSelectmodel isOpen={isSecondModalOpen} onClose={handleCloseSecondModal} />
      </div>

           
    </>
  );
};

export default Channel;
